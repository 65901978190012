<template>
  <div>
    <el-tabs v-model="activeName" type="card" @tab-click="change">
      <el-tab-pane :label="$t('cs.scxy')" name="first"
        ><agreements></agreements
      ></el-tab-pane>
      <el-tab-pane :label="$t('cs.bdxy')" name="second">
        <el-form ref="bindformref" :model="bindform" label-width="140px">
          <el-form-item :label="$t('cs.zcxy')" prop="regirest">
            <el-select
              v-model="bindform.regirest"
              multiple
              :placeholder="$t('qxz')"
            >
              <el-option
                v-for="item in bindlist"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('cs.yhdlxy')" prop="login">
            <el-select
              v-model="bindform.login"
              multiple
              :placeholder="$t('qxz')"
            >
              <el-option
                v-for="item in bindlist"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('cs.jjtzxgxy')" prop="fund">
            <el-select
              v-model="bindform.fund"
              multiple
              :placeholder="$t('qxz')"
            >
              <el-option
                v-for="item in bindlist"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit">{{
              $t('tijiao')
            }}</el-button>
            <el-button @click="reset">{{ $t('chongzhi') }}</el-button>
          </el-form-item>
        </el-form>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import agreements from '@/components/config/agreements.vue'
export default {
  components: {
    agreements
  },
  data() {
    return {
      activeName: 'first',
      bindform: {
        regirest: [],
        login: [],
        fund: []
      },
      bindlist: []
    }
  },
  methods: {
    async getlist() {
      const loading = this.$loading({
        lock: true,
        spinner: 'el-icon-loading',
        background: 'rgba(255,255, 255, 0.7)',
        target: document.querySelector('.el-main')
      })
      const { data } = await this.$http.get('/admin/config/bindagreement')
      if (data) {
        if (data.code === 200) {
          this.bindlist = data.data.list
          this.bindform = data.data.ids
        } else {
          this.$message.error(this.$t('shibai'))
        }
      }
      loading.close()
    },
    async onSubmit() {
      const { data } = await this.$http.post(
        '/admin/config/bindagreement',
        this.bindform
      )
      if (data) {
        if (data.code === 200) {
          this.$message.success(this.$t('chenggong'))
        } else {
          this.$message.error(this.$t('shibai'))
        }
      }
    },
    reset() {
      this.getlist()
    },
    change() {
      if (this.activeName === 'second') {
        this.getlist()
      }
    }
  }
}
</script>
<style lang="less" scoped>
.el-select {
  width: 350px;
}
</style>

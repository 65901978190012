<template>
  <div>
    <el-form :inline="true">
      <!-- 添加/搜索区域 -->
      <el-form-item>
        <el-button @click="addagreement">{{ $t('xinzeng') }}</el-button>
      </el-form-item>
      <!-- 列表区域 -->
      <el-form-item>
        <el-input
          v-model="queryinfo.query"
          :clearable="true"
          @clear="getlist"
          style="width:240px"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="getlist"
          ></el-button>
        </el-input>
      </el-form-item>
    </el-form>
    <el-table
      v-loading="loading"
      :data="agreementlist"
      border
      style="width: 100%;"
    >
      <el-table-column prop="id" label="#"></el-table-column>
      <el-table-column prop="name" :label="$t('cs.xymc')"></el-table-column>
      <el-table-column :label="$t('caozuo')" width="150px">
        <template slot-scope="{ row }">
          <el-tooltip
            :content="$t('bianji')"
            placement="top"
            :enterable="false"
          >
            <i class="iconfont icon-bianji" @click="edit(row.id)"></i>
          </el-tooltip>
          <el-tooltip
            :content="$t('shanchu')"
            placement="top"
            :enterable="false"
          >
            <i class="el-icon-close" @click="del(row.id)"></i>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页区域 -->
    <el-pagination
      :hide-on-single-page="total <= 10"
      background
      layout="prev, pager, next, total"
      :page-size="10"
      :total="total"
      :current-page="queryinfo.page"
      @current-change="changepage"
    ></el-pagination>
    <!-- 添加弹窗区域 -->
    <el-dialog
      :title="$t('xinzeng')"
      :visible="dialogvisible"
      :before-close="handleClose"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form
        :model="addform"
        label-width="150px"
        ref="addref"
        :rules="addrules"
      >
        <el-form-item :label="$t('cs.xymc')" prop="name">
          <el-input v-model="addform.name"></el-input>
        </el-form-item>
        <el-form-item :label="$t('cs.xynr')" prop="content" required>
          <div id="editor"></div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submit">{{ $t('tijiao') }}</el-button>
        <el-button @click="handleClose">{{ $t('quxiao') }}</el-button>
      </div>
    </el-dialog>
    <!-- 编辑弹窗区域 -->
    <el-dialog
      :title="$t('bianji')"
      :visible="editdialogvisible"
      :before-close="editclose"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form
        :model="addform"
        label-width="150px"
        ref="addref"
        :rules="addrules"
      >
        <el-form-item :label="$t('cs.xymc')" prop="name">
          <el-input v-model="addform.name"></el-input>
        </el-form-item>
        <el-form-item :label="$t('cs.xynr')" prop="content" required>
          <div id="editeditor"></div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="editsubmit">{{
          $t('tijiao')
        }}</el-button>
        <el-button @click="editclose">{{ $t('quxiao') }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import E from 'wangeditor'
export default {
  data() {
    return {
      // 分页请求数据对象
      queryinfo: {
        query: '',
        page: 1
      },
      agreementlist: [],
      total: 0,
      editor: '',
      editeditor: '',
      loading: true,
      // 添加/编辑数据对象
      addform: {
        name: '',
        content: ''
      },
      dialogvisible: false,
      editdialogvisible: false,
      addrules: {
        name: [{ required: true, message: '请填写名称', trigger: 'blur' }]
      }
    }
  },
  created() {
    this.getlist()
  },
  methods: {
    async getlist() {
      const { data } = await this.$http.get('/admin/config/agreementconfig', {
        params: this.queryinfo
      })
      this.agreementlist = data.data.list
      this.loading = false
    },
    // 添加分类弹窗
    addagreement() {
      this.dialogvisible = true
      // 编辑器
      this.$nextTick(v => {
        if (this.editor) return false
        this.editor = new E('#editor')
        this.editor.config.onchange = html => {
          this.addform.content = html
        }
        this.editor.config.menus = [
          'head',
          'bold',
          'fontSize',
          'italic',
          'underline',
          'strikeThrough',
          'foreColor',
          'link',
          'justify',
          'image',
          'table',
          'undo',
          'redo'
        ]
        this.editor.create()
      })
    },
    // 关闭添加弹窗
    handleClose() {
      this.editor.txt.html('')
      this.$refs.addref.resetFields()
      this.dialogvisible = false
    },
    // 分页事件
    changepage(val) {
      this.queryinfo.page = val
      this.getlist()
    },
    // 提交添加分类
    submit() {
      this.$refs.addref.validate(async valid => {
        if (!valid) return false
        const { data } = await this.$http.post(
          '/admin/config/addagreement',
          this.addform
        )
        if (data) {
          if (data.code === 200) {
            this.$message.success(this.getlang(data.msg))
            this.handleClose()
            this.getlist()
          } else {
            this.$message.error(this.getlang(data.msg))
          }
        }
      })
    },
    // 删除操作
    del(id) {
      this.$confirm(this.$t('shanchutips'), this.$t('tishi'), {
        confirmButtonText: this.$t('queding'),
        cancelButtonText: this.$t('quxiao'),
        type: 'warning'
      }).then(async () => {
        const { data } = await this.$http.post('/admin/config/delagreement', {
          id
        })
        if (data) {
          if (data.code === 200) {
            this.$message.success(this.getlang(data.msg))
            this.getlist()
          } else {
            this.$message.error(this.getlang(data.msg))
          }
        }
      })
    },
    // 打开编辑弹窗
    async edit(id) {
      this.editdialogvisible = true
      const loading = this.$loading({
        lock: true,
        spinner: 'el-icon-loading',
        background: 'rgba(255,255, 255, 0.7)',
        target: document.querySelector('.el-dialog__body')
      })
      // 编辑器
      this.$nextTick(v => {
        if (this.editeditor) return false
        this.editeditor = new E('#editeditor')
        this.editeditor.config.onchange = html => {
          this.addform.content = html
        }
        this.editeditor.config.menus = [
          'head',
          'bold',
          'fontSize',
          'italic',
          'underline',
          'strikeThrough',
          'foreColor',
          'link',
          'justify',
          'image',
          'table',
          'undo',
          'redo'
        ]
        this.editeditor.create()
      })
      const { data } = await this.$http.get(
        '/admin/config/editagreement/id/' + id
      )
      if (data) {
        if (data.code === 200) {
          this.addform.name = data.data.name
          this.addform.content = data.data.content
          this.editid = data.data.id
          this.editeditor.txt.html(data.data.content)
        } else {
          this.$message.error(this.$t('shibai'))
        }
      }
      loading.close()
    },
    // 提交编辑
    async editsubmit() {
      this.addform.id = this.editid
      const { data } = await this.$http.post(
        '/admin/config/editagreement',
        this.addform
      )
      if (data) {
        if (data.code === 200) {
          this.$message.success(data.msg)
          this.editdialogvisible = false
          this.$refs.addref.resetFields()
          this.getlist()
        } else {
          this.$message.error(this.getlang(data.msg))
        }
      }
    },
    // 关闭编辑弹窗
    editclose() {
      this.editeditor.txt.html('')
      this.$refs.addref.resetFields()
      this.editdialogvisible = false
    }
  }
}
</script>

<style lang="less" scoped>
.iconfont,
.el-icon-close {
  font-size: 18px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-right: 15px;
  padding: 1px;
  &:hover {
    color: #409eff;
    cursor: pointer;
    border-color: #409eff;
  }
}
.el-select {
  min-width: 300px;
}
</style>
